/* -------------------- GENERAL -------------------- */

:root {  
  --color-fg-1: #3d405f;
  --color-fg-2: #72738a;

  --color-bg-1: #ffffff;
  --color-bg-2: #efedf3;
  --color-bg-3: #dddae4;

  --color-blue-1: #84ccdf;
  --color-blue-2: #9af6e2;
  --color-blue-3: #3e9df5;
  --color-blue-4: #177fe0;

  --color-orange-1: #fda95a;

  --color-pink-1: #ffc0cb;

  --color-purple-1: #a881c7;

  --color-gold-1: #e0c56a;
  --color-yellow-2: #ffe75c;
}


[data-theme="dark"] {
  --color-fg-1: #eae9f5;
  --color-fg-2: #c1c0cf;

  --color-bg-1: #393950;
  --color-bg-2: #232027;
  --color-bg-3: #201c25;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--color-bg-2);
  margin: 0 auto;
  overflow-x: hidden;
}

header, main {
  padding: 12px;
  color: var(--color-fg-1);
}

main {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

h1, h2, p {
  margin: 0;
  color: var(--color-fg-1);
}

p {
  line-height: 24px;
}

section {
  margin: 24px 0;
}

summary {
  list-style: none;
}

summary::marker {
  display: none;
}

/* -------------------- HEADER -------------------- */

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  background-color: var(--color-bg-1);
  padding: 6px;
  z-index: 1;
}

.controls b {
  display: none;
  align-self: center;
}

.controls b span {
  opacity: 0.5;
}

label {
  background-color: var(--color-blue-3);
  padding: 9px;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: white;
}

label:active,
label:hover {
  background-color: var(--color-blue-4);
  transition: background-color 0.25s ease;
}

label div {
  content: "";
  position: absolute;
  top: 6px;
  background-color: var(--color-bg-1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.6);
}

label [type="checkbox"] {
  display: none;
}

label span {
  width: 54px;
  text-align: center;
  font-weight: 600;
}

label div {
  transition: all .25s ease;
}

label [type="checkbox"] ~ span:first-of-type {
  display: block;
  margin-left: 30px;
}

label [type="checkbox"] ~ span:last-of-type {
  display: none;
}

label [type="checkbox"] ~ div {
  left: 6px;
}

label [type="checkbox"]:checked ~ span:first-of-type {
  display: none;
}

label [type="checkbox"]:checked ~ span:last-of-type {
  display: block;
  margin-right: 30px;
}

label [type="checkbox"]:checked ~ div {
  left: calc(100% - 30px);
}

/* -------------------- PROFILE -------------------- */

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.outer-circle-1 {
  animation: 90s linear 1s infinite forwards rotate360;
}

.outer-circle-2 {
  animation: 90s linear 1s infinite reverse rotate360;
}

.outer-circle-3 {
  animation: 180s linear 1s infinite forwards rotate360;
}


@media (prefers-reduced-motion: reduce) {
  .outer-circle-1,
  .outer-circle-2,
  .outer-circle-3 {
    animation: none;
  }
}

.outer-circle-1 path {
  fill: none;
  stroke-width: 10px;
}

.outer-circle-1 path,
.outer-circle-2 path,
.outer-circle-3 path {
  stroke: var(--color-fg-1);
}

.outer-circle-2 path,
.outer-circle-3 path {
  fill: var(--color-fg-1);
}

.TH > path {
  fill: var(--color-purple-1);
}

.DEV > path {
  fill: var(--color-gold-1);
}

.ATA > path {
  fill: var(--color-blue-1);
}

.logo, .logo svg {
  background-color: var(--color-bg-2);
}

.logo svg {
  display: block;
  margin: 0 auto;
  max-width: 360px;
}

.about {
  margin: 96px 0 120px;
  text-align: center;
}

.about h1 {
  width: 13ch;
}

.about p {
  width: 18ch;
  color: var(--color-fg-2);
}

.tagline-container {
  display: block;
  max-width: 28ch;
  height: 120px;
  position: relative;
}

@keyframes slideup {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slideup2 {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slidedown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.tagline-container b {
  color: var(--color-fg-1);
  font-weight: 700;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(100%);
  text-align: center;
}

.tagline-container b.should-slideup {
  animation: slideup 0.5s ease forwards;
}

.tagline-container b.should-slidedown {
  animation: slidedown 0.25s ease forwards;
}

.about h1,
.about p,
.tagline-container b {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0 auto;
}

.contacts {
  display: flex;
  justify-content: center;
}

.contacts li + li {
  margin-left: 5vw;
}

.contacts a {
  color: var(--color-blue-3);
  font-size: 30px;
}

.contacts a:hover {
  color: var(--color-blue-4);
}


/* -------------------- PORTFOLIO -------------------- */

.project-card h2 {
  padding: 0;
  font-size: 2.5rem;
  line-height: 2.5rem;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.2);
  text-decoration-thickness: 6px;
}

.project-card {
  position: relative;
}

.project-card:nth-of-type(1) .summary {
  background-color: var(--color-yellow-2);
}

.project-card:nth-of-type(2) .summary {
  background-color: var(--color-blue-2);
}

.project-card:nth-of-type(3) .summary {
  background-color: var(--color-orange-1);
}

.project-card:nth-of-type(4) .summary {
  background-color: var(--color-pink-1);
}

.project-card p {
  margin-top: 24px;
}

.project-card p,
.project-card h2 {
  color: #3d405f;
}

.project-card .summary {
  padding: 48px 54px 72px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  position: relative;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 90%);
}

.project-card .summary > p > p {
  display: inline;
}

.project-card .link-cta {
  margin-left: 6px;
  font-weight: bold;
  color: var(--color-blue-3);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.project-card .content {
  padding: 48px 54px 72px;
  background-color: var(--color-bg-1);
  border-bottom-right-radius: 18px;
}

.project-card .content p {
  color: var(--color-fg-1);
}

.project-card[open] .summary {
  border-bottom-right-radius: 0;
  clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);
}

.project-card[open] .content {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 96%);
}

.project-card[open] .content p {
  animation: slideup2 0.5s ease forwards;
}

.project-card .card-link {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 48px;
  color: rgba(0, 0, 0, 0.2);
}

.project-card .demo-link {
  display: inline-block;
  padding: 12px 36px;
  margin: 24px 0;
  border-radius: 6px;
  background-color: var(--color-blue-3);
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.project-card .demo-link:hover {
  background-color: var(--color-blue-4);
}

.project-card .demo-link i {
  margin-left: 6px;
}

.project-card .preview {
  position: relative;
}

.project-card .preview-media {
  max-width: 100%;
}

.project-card .preview .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 5vmin;
  min-width: 54px;
  height: 5vmin;
  min-height: 54px;
  font-size: 54px;
  border-radius: 50%;
  padding: 3px;
  background-color: var(--color-bg-1);
  color: var(--color-blue-3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .9;
}

.project-card .preview .play:hover {
  opacity: 1;
  filter: brightness(.95);
  cursor: pointer;
  color: var(--color-blue-4);
}

.project-card .preview .play[aria-hidden="true"] {
  display: none;
}

.preview-media[data-should-rotate=true] {
  transform: rotate(90deg) scale(1.4);
  clip-path: polygon(30% 0%, 70% 0%, 70% 100%, 30% 100%);
}

.project-card:not([open]) a:hover {
  color: rgba(0, 0, 0, 0.6);
}

.project-card:not([open]):hover {
  transition: transform 0.25s ease;
}

/* -------------------- BREAKPOINTS -------------------- */

@media screen and (min-width: 500px) {
  .controls b {
    display: block;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .about {
    text-align: left;
  }

  .about h1, .about p {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
  }

  .about b {
    font-size: 2rem;
  }

  .logo {
    margin-top: -96px;
  }

  .tagline-container {
    text-align: left;
  }

  .tagline-container b {
    right: auto;
    text-align: left;
  }
}


@media screen and (min-width: 900px) {
  main {
    display: flex;
    flex-direction: row;
  }

  .profile {
    display: block;
    width: 400px;
    margin: 0;
    position: fixed;
    top: 0;
    bottom: 0;
  }

  .logo {
    height: 35vh;
    max-height: 360px;
    margin-top: 96px;
  }

  .about {
    margin-top: 48px;
  }

  .about h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .about p {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .portfolio {
    margin-left: 450px;
    margin-top: 60px;
  }

  @keyframes glimmer {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .glimmer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate3d(-100%, 0, 0);
    pointer-events: none;
  }
  
  .glimmer::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 25%;
    transform: translateX(-50%) skew(-30deg);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    );
  }
  
  .project-card:not([open]) .summary:hover > .glimmer {
    animation: glimmer 1s ease;
  }
}


@media screen and (min-width: 500px) and (max-height: 700px){
  .about h1,
  .about p,
  .tagline-container b {
    font-size: 6vmin;
    line-height: 6vmin;
  }  
}

@media screen and (min-width: 900px) and (max-height: 700px){
  .logo {
    position: fixed;
    top: 40vh;
    left: 10vh;
    transform: scale(2);
    opacity: 0.025;
  }
  .about {
    margin-top: 96px;
    margin-left: 12px;
  }
}